<template>
  <div
    v-if="showLogoutModal"
    ref="confimration_modal"
    :class="{ show: showLogoutModal === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <transition-group name="slide" appear>
          <div class="modal__header mb-3" key="header">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                Session Timeout!
              </span>
            </div>
          </div>
          <div class="modal__content" key="content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-5">
                Hi {{ username }},
              </h5>
              <p class="form__input">
                You are about to be logged out due to inactivity. <br>
                Do you want to continue?
              </p>
              <!-- <div>
                <BaseInput
                  label="Username"
                  placeholder="Username"
                  :value="username"
                  type="text"
                  disbaled
                >
                </BaseInput>
                <BaseInput
                  label="password"
                  placeholder="Password"
                  v-model="password"
                  type="password"
                >
                </BaseInput>
              </div> -->
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button form__button form__button--lg
                    ml-2
                    save-changes
                  "
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button form__button form__button--lg
                    ml-2
                    mr-2
                    save-changes
                  "
                  @click="login"
                  v-else
                >
                  Yes
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--md
                    modal__confirm__button modal__confirm__button--cancel
                  "
                  @click="logoutUser"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
import { LOGOUT, REFRESH_TOKEN } from "@/core/services/store/types";
import { mapState } from "vuex";
// import { required } from "vuelidate/lib/validators";
// import BaseInput from "@/components/Inputs/_base-Input.vue";

export default {
  name: 'UserSession',
  components: {
    // BaseInput,
  },
  computed: {
    ...mapState({
      username: (state) => state.auth?.user?.userName || "",
      tokenExpiresIn: (state) => state.auth?.user?.expires_In,
    }),
    showLogoutModal() {
      return (this.warningMessage);
    }
  },
  data() {
    return {
      events: ['click', 'mousedown', 'mousemove', 'scroll', 'keypress', 'load'],
      warningMessage: false,
      password: "",
      loading: false,
      setTimeoutId: null,
      hasRefresh: false,
      refreshTokenTime: 0,
    }
  },
  // validations: {
  //   password: {
  //     required,
  //   },
  // },
  mounted() {
    this.setTimers();
  },
  methods: {
    setTimers() {
      let timeSinceLastActivity = 0;
      let maxInactiveTime = (this.tokenExpiresIn - 4) * 60 * 1000;
      this.refreshTokenTime = (this.tokenExpiresIn - 2) * 60 * 1000;
      const counterIncrement = 1 * 1000;
      const tokenExpirationTime = this.tokenExpiresIn * 60 * 1000;

      setInterval(function() {
        timeSinceLastActivity += counterIncrement;

        if (timeSinceLastActivity > maxInactiveTime) {
          timeSinceLastActivity;
          this.setWarning();
        }

        if (timeSinceLastActivity > tokenExpirationTime) {
          this.logoutUser();
        }
      }.bind(this), 1000);

      const activity = () => {
        timeSinceLastActivity = 0;

        if (this.hasRefresh && !this.warningMessage) {
          this.hasRefresh = false;
          this.refreshTokenTime = (this.tokenExpiresIn - 2) * 60 * 1000;
          clearTimeout(this.setTimeoutId);
          this.refreshTokenTimer();
        }
      }

      this.events.map((event) => {
        window.addEventListener(event, activity, true);
      });

      this.refreshTokenTimer();
    },

    refreshTokenTimer() {
      this.setTimeoutId = setTimeout(function() {
        if (!this.warningMessage) {
          this.$store.dispatch(REFRESH_TOKEN).then(() => {
            this.hasRefresh = true;
          });
        }
      }.bind(this), this.refreshTokenTime);
    },
    
    setWarning() {
      this.warningMessage = true;
    },

    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    logoutUser() {
      this.$store.dispatch(LOGOUT);
      this.$router.push({ name: "login" });
      this.warningMessage = false;
    },

    async login() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.$emit('alertError', "Password can not be empty");
      //   return;
      // }
      this.loading = true;
      this.$store
      .dispatch(REFRESH_TOKEN)

      // closes session timeout modal
      .then(() => {
        this.loading = false;
        this.warningMessage = false;
        // this.$router.push({ path: "/dashboard" });
      })
      .catch((e) => {
        this.loading = false;
        this.$emit('alertError', e.data.message);
      });
    },
  }
}
</script>
<style>

</style>